<template>
  <div class="card mb-4">
    <div class="card-header">{{ label }}</div>
    <div class="card-body-">
      <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-per-page-change="onPerPageChange"
        @on-search="onSearch"
        :totalRows="totalRecords"
        :columns="columns"
        :rows="tickets"
        :line-numbers="true"
        :search-options="{
          enabled: true,
          trigger: 'enter',
          placeholder: 'Search this list...'
        }"
        :pagination-options="{
          mode: 'pages',
          enabled: true,
          perPage: 500,
          perPageDropdown: [100, 200, 500]
        }"
        :sort-options="{
          enabled: true,
          multipleColumns: false
        }"
        styleClass="vgt-table condensed myGoodTable"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'ref'">
            <!-- {{ props.row.id }} -->
            <router-link :to="{ name: routeName, params: { ref: props.row.ref } }">
              <b>{{ props.row.ref }}</b>
            </router-link>
          </span>
          <span v-if="props.column.field == 'status'">
            <span v-if="props.row.status == 'Open'" class="badge badge-danger">{{ props.row.status }} </span>
            <span v-if="props.row.status == 'Closed'" class="badge badge-success">{{ props.row.status }} </span>
            <span v-if="props.row.status == 'Escalated'" class="badge badge-warning">{{ props.row.status }} </span>
            <span v-if="props.row.status == 'Reopened'" class="badge badge-info">{{ props.row.status }} </span>
            <span v-if="props.row.status == 'InProgress'" class="badge badge-primary">{{ props.row.status }} </span>
          </span>
          <span v-if="props.column.field == 'category'"> {{ props.row.category }} </span>
          <span v-if="props.column.field == 'subcategory'">{{ props.row.subcategory }}</span>
          <span v-if="props.column.field == 'matric'">{{ props.row.matric }}</span>
          <span v-if="props.column.field == 'createdBy'">{{ props.row.createdBy }}</span>
          <span v-if="props.column.field == 'assignedTo'">{{ props.row.assignedTo }}</span>
          <span v-if="props.column.field == 'createdAt'">{{ props.row.createdAt }}</span>
          <!--
       -->
          <span v-if="props.column.field == 'escalatedByUser'">{{ props.row.escalatedByUser }}</span>
          <span v-if="props.column.field == 'escalatedAt'">{{ props.row.escalatedAt }}</span>
          <span v-if="props.column.field == 'escalatedToUser'">{{ props.row.escalatedToUser }}</span>

          <span v-if="props.column.field == 'closedByUser'">{{ props.row.closedByUser }}</span>
          <span v-if="props.column.field == 'closedAt'">{{ props.row.closedAt }}</span>

          <span v-if="props.column.field == 'reOpenedByUser'">{{ props.row.reOpenedByUser }}</span>
          <span v-if="props.column.field == 'reopenedAt'">{{ props.row.reopenedAt }}</span>
        </template>
        <div slot="table-actions">
          <SearchForm @searchTickets="searchTickets" @exportHandler="exportHandler"></SearchForm>
        </div>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import SearchForm from './SearchForm.vue'
import exportCSV from '../helper/exportCSV'

export default {
  components: {
    SearchForm
  },

  props: {
    //tickets: { type: Array },
    //totalRecords: Number,
    routeName: String
  },

  data() {
    return {
      status: 'all',
      tickets: [{ id: 0 }],
      ticketBKP: [{ id: 0 }],
      ticketDownloads: [{ id: 0 }],
      label: 'Tickets',
      isLoading: false,

      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: 500
      },
      firstID: 0,
      lastID: 0,
      myparam: {},
      path: 'tickets',
      download: false
    }
  },

  mounted() {
    //let status = 'all'
    if (this.$route.params.status != undefined) {
      this.status = this.$route.params.status
    }
    this.loadItems()
  },

  watch: {
    '$route.params.status': function(status) {
      window.location.reload()
      this.tickets = []
      this.status = status
      this.loadItems()
      window.scrollTo(0, 0)
    }
  },

  methods: {
    async loadItems() {
      this.myparam = {
        ...this.myparam,
        goodTableParams: JSON.stringify(this.serverParams),
        status: this.status,
        firstID: this.firstID,
        lastID: this.lastID
      }
      //let path = `/${this.authUserRole}/tickets?goodTableParams=${JSON.stringify(this.serverParams)}&status=${this.status}&firstID=${this.firstID}&lastID=${this.lastID}`
      //let path = `/${this.authUserRole}/tickets`
      let searchType = this.$route.query.searchType
      let searchTerm = this.$route.query.searchTerm
      if (searchType !== '' && searchType !== undefined) {
        //path = `/${this.authUserRole}/search-tickets?goodTableParams=${JSON.stringify(this.serverParams)}&searchType=${searchType}&searchTerm=${searchTerm}&firstID=${this.firstID}&lastID=${this.lastID}`
        //path = `/${this.authUserRole}/search-tickets`
        this.path = 'search-tickets'
        this.myparam = { ...this.myparam, searchType: searchType, searchTerm: searchTerm }
      }

      let loading = this.$loading.show()
      await axios
        .get(`/${this.authUserRole}/${this.path}`, {
          params: this.myparam
        })
        .then(res => {
          if (res.data.status == 'success') {
            if (this.download) {
              this.ticketDownloads = res.data.tickets
              this.download = false
              return
            }
            this.tickets = res.data.tickets
            this.ticketBKP = res.data.tickets
            this.totalRecords = res.data.totalRecords
            this.label = res.data.label
            if (this.lastID === 0) {
              this.firstID = res.data.tickets[0].id
              this.lastID = res.data.tickets[res.data.tickets.length - 1].id
              /* console.log(this.firstID)
            console.log(this.lastID)
            console.log(res.data.tickets[res.data.tickets.length - 1].id) */
            }
          } else {
            //this.$swal("Ooops!", res.data.message, "error");
            this.tickets = []
          }
          loading.hide()
        })
    },

    searchTickets(data) {
      this.myparam = {
        ...this.myparam,
        searchType: data.searchType,
        searchTerm: data.searchTerm,
        ticketStatusID: data.ticketStatusID,
        categoryID: data.categoryID,
        subcategoryID: data.subcategoryID,
        studycenterID: data.studycenterID,
        facultyID: data.facultyID,
        programmeID: data.programmeID,
        startDate: data.startDate,
        endDate: data.endDate
      }
      this.path = 'search-tickets'
      this.loadItems()
      /* this.tickets = data.tickets
      this.label = data.label
      this.totalRecords = data.totalRecords */
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      /* this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems() */
      if (params[0].type === 'desc') {
        this.tickets.reverse()
        return
      }

      const field = params[0].field
      this.tickets.sort((a, b) => {
        if (a[field] < b[field]) {
          return -1
        }
        if (a[field] > b[field]) {
          return 1
        }
        return 0
      })
    },

    onSearch(params) {
      console.log(params.searchTerm)
      let tickets = []
      let searchTerm = params.searchTerm

      tickets = this.ticketBKP.filter(el => {
        return (
          el.ref.includes(searchTerm) ||
          el.category.includes(searchTerm) ||
          el.subcategory.includes(searchTerm) ||
          el.matric.includes(searchTerm) ||
          el.assignedTo.includes(searchTerm) ||
          el.createdBy.includes(searchTerm) ||
          el.createdAt.includes(searchTerm) ||
          el.escalatedByUser.includes(searchTerm) ||
          el.escalatedAt.includes(searchTerm) ||
          el.closedByUser.includes(searchTerm) ||
          el.closedAt.includes(searchTerm) ||
          el.reOpenedByUser.includes(searchTerm) ||
          el.reopenedAt.includes(searchTerm)
        )
      })

      this.tickets = tickets
    },

    async exportHandler() {
      let headers = {
        ref: 'ReferenceID',
        status: 'Status',
        category: 'Category',
        subcategory: 'Subcategory',
        matric: 'Matric',
        assignedTo: 'AssignedTo',
        createdBy: 'CreatedBy',
        createdAt: 'CreatedAt',
        escalatedByUser: 'EscalatedBy',
        escalatedAt: 'EscalatedAt',
        closedByUser: 'ClosedBy',
        closedAt: 'ClosedAt',
        reOpenedByUser: 'ReopenedBy',
        reopenedAt: 'ReopenedAt'
      }
      let data = []
      let fileName = 'tickets-' + new Date().getTime()

      // save current state
      let firstID = this.firstID
      let lastID = this.lastID
      let page = this.serverParams.page
      let perPage = this.serverParams.perPage

      // Changed to download all data
      this.download = true
      this.firstID = 0
      this.lastID = 0
      this.serverParams.page = 1
      this.serverParams.perPage = this.totalRecords > 10000 ? 10000 : this.totalRecords
      // load all data
      await this.loadItems()

      this.ticketDownloads.forEach(el => {
        data.push({
          ReferenceID: el.ref.replace(/,/g, ''),
          Status: el.status.replace(/,/g, ''),
          Category: el.category.replace(/,/g, ''),
          Subcategory: el.subcategory.replace(/,/g, ''),
          Matric: el.matric.replace(/,/g, ''),
          AssignedTo: el.assignedTo.replace(/,/g, ''),
          CreatedBy: el.createdBy.replace(/,/g, ''),
          CreatedAt: el.createdAt.replace(/,/g, ''),
          EscalatedBy: el.escalatedByUser.replace(/,/g, ''),
          EscalatedAt: el.escalatedAt.replace(/,/g, ''),
          ClosedBy: el.closedByUser.replace(/,/g, ''),
          ClosedAt: el.closedAt.replace(/,/g, ''),
          ReopenedBy: el.reOpenedByUser.replace(/,/g, ''),
          ReopenedAt: el.reopenedAt.replace(/,/g, '')
        })
      })

      exportCSV(headers, data, fileName)

      // restore state
      this.download = false
      this.firstID = firstID
      this.lastID = lastID
      this.serverParams.page = page
      this.serverParams.perPage = perPage
      loading.hide()
    }
  },

  computed: {
    authUserRole() {
      return this.$store.state.authUser.Role
    },

    columns() {
      let cols = [
        {
          label: 'ReferenceID',
          field: 'ref'
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'Category',
          field: 'category'
        },
        {
          label: 'Subcategory',
          field: 'subcategory'
        },
        {
          label: 'Matric',
          field: 'matric'
        },
        {
          label: 'AssignedTo',
          field: 'assignedTo'
        },
        {
          label: 'CreatedBy',
          field: 'createdBy'
        },
        {
          label: 'CreatedAt',
          field: 'createdAt'
        },

        {
          label: 'EscalatedBy',
          field: 'escalatedByUser'
        },
        {
          label: 'EscalatedAt',
          field: 'escalatedAt'
        },

        {
          label: 'ClosedBy',
          field: 'closedByUser'
        },
        {
          label: 'ClosedAt',
          field: 'closedAt'
        },

        {
          label: 'ReopenedBy',
          field: 'reOpenedByUser'
        },
        {
          label: 'ReopenedAt',
          field: 'reopenedAt'
        }

        /*  {
          label: 'EscalatedTo',
          field: 'escalatedToUser'
        }, */
      ]
      return cols
    }
  }
}
</script>

<style>
.myGoodTable {
  font-size: 0.8rem !important;
}
</style>
