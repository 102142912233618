var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.tickets,"line-numbers":true,"search-options":{
    enabled: true
  },"pagination-options":{
    enabled: true,
    perPage: 100
  },"sort-options":{
    enabled: true,
    multipleColumns: true
  },"styleClass":"vgt-table condensed myGoodTable"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'ref')?_c('span',[_c('router-link',{attrs:{"to":{ name: _vm.routeName, params: { ref: props.row.ref } }}},[_c('b',[_vm._v(_vm._s(props.row.ref))])])],1):_vm._e(),(props.column.field == 'status')?_c('span',[(props.row.status == 'Open')?_c('span',{staticClass:"badge badge-danger"},[_vm._v(_vm._s(props.row.status)+" ")]):_vm._e(),(props.row.status == 'Closed')?_c('span',{staticClass:"badge badge-success"},[_vm._v(_vm._s(props.row.status)+" ")]):_vm._e(),(props.row.status == 'Escalated')?_c('span',{staticClass:"badge badge-warning"},[_vm._v(_vm._s(props.row.status)+" ")]):_vm._e(),(props.row.status == 'Reopened')?_c('span',{staticClass:"badge badge-info"},[_vm._v(_vm._s(props.row.status)+" ")]):_vm._e(),(props.row.status == 'InProgress')?_c('span',{staticClass:"badge badge-primary"},[_vm._v(_vm._s(props.row.status)+" ")]):_vm._e()]):_vm._e(),(props.column.field == 'category')?_c('span',[_vm._v(" "+_vm._s(props.row.category)+" ")]):_vm._e(),(props.column.field == 'subcategory')?_c('span',[_vm._v(_vm._s(props.row.subcategory))]):_vm._e(),(props.column.field == 'matric')?_c('span',[_vm._v(_vm._s(props.row.matric))]):_vm._e(),(props.column.field == 'createdBy')?_c('span',[_vm._v(_vm._s(props.row.createdBy))]):_vm._e(),(props.column.field == 'assignedTo')?_c('span',[_vm._v(_vm._s(props.row.assignedTo))]):_vm._e(),(props.column.field == 'createdAt')?_c('span',[_vm._v(_vm._s(props.row.createdAt))]):_vm._e(),(props.column.field == 'currentStatusDate')?_c('span',[_vm._v(_vm._s(props.row.currentStatusDate))]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }